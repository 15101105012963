export const isDev: boolean = (() => {
  const searchParams = new URLSearchParams(location.search);
  const locationDev = searchParams.get("_env");
  const hostName = location.hostname;
  // hostName.includes("172") ||

  // return false; // TODO remove

  if (locationDev === "prod") {
    return false;
  }

  if (
    hostName.includes("127.0.0.1") ||
    hostName.includes("172.26.1.16") ||
    hostName.includes("localhost") ||
    hostName.includes("local") ||
    hostName.includes("dev")
  ) {
    return true;
  } else {
    return false;
  }
})();

export const isProd = !isDev;

export const isLinjieApp = () => {
  return navigator.userAgent.includes("linjie");
};
